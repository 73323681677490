<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__actions"></div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input full-size">
            <input type="text" v-model="filterModel.name" :placeholder="$t('workspace.station_config.filter_for') + $t('workspace.station_config.name')">
          </div>
          <button :disabled="filterModel.name == ''" @click="filterModel.name = ''">{{ $t("workspace.clean_filter") }}</button>
        </div>
      </div>
    </div>


    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.station_config.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="listStations(filterModel).length > 0">
          <tr v-for="item in listStations(filterModel)" :key="item.code">
            <td v-html="highlightText(item.name, filterModel.name)"></td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="3">{{$t('workspace.none_results_with_filters')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core';
import highlightText from '@/plugins/Mixin/highlightText.js';

export default {
  mixins: [highlightText],
  setup() {
    const store = useStore();
    const listStations = computed(() => filterModel => store.getters.station_stations(filterModel));
    const filterModel = ref({
      name: ''
    });

    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      await store.dispatch('station_getAll');
      store.dispatch('loading_setActive', false);
    });

    return {
      listStations,
      filterModel
    }
  }
}
</script>
<style lang="">
  
</style>